/* Definitions.css */

/* Main container for the component */
.course-dropdown-container {
  max-width: 800px; /* Max width of the container */
  margin: 0 auto; /* Centering the container */
  padding: 20px; /* Padding inside the container */
  background-color: #f9f9f9; /* Light background color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

/* Search input styling */
.search-input {
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the input */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 15px; /* Space below the input */
  font-size: 16px; /* Font size */
}

/* Dropdown select styling */
.course-select {
  width: 100%; /* Full width */
  padding: 10px; /* Padding inside the select */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 15px; /* Space below the select */
  font-size: 16px; /* Font size */
}

/* Container for filtered topics */
.topics-container {
  margin-top: 20px; /* Space above the topic list */
}

/* Individual topic card styling */
.topic-card {
  background-color: #ffffff; /* White background */
  padding: 15px; /* Padding inside the card */
  border-radius: 6px; /* Rounded corners */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 15px; /* Space between cards */
}

/* Title styling for topic */
.topic-title {
  font-size: 20px; /* Larger font size for title */
  margin-bottom: 10px; /* Space below title */
}

/* Definition styling for topic */
.topic-definition {
  font-size: 16px; /* Font size for definition */
  margin-bottom: 15px; /* Space below definition */
}

/* Recommendations container */
.recommendations-container {
  display: flex; /* Flexbox for horizontal layout */
  flex-wrap: wrap; /* Wrap if needed */
}

/* Styling for recommendation buttons */
.recommendation-button {
  background-color: #007bff; /* Default button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 8px 12px; /* Padding */
  margin: 5px; /* Margin */
  border-radius: 20px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s; /* Transition effect */
  font-size: 14px; /* Font size */
}

.recommendation-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 80%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.slideshow-container {
  text-align: center;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  cursor: pointer;
}

.slideshow-button {
  margin-top: 20px;
  padding: 10px 20px;
  cursor: pointer;
}
