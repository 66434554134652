/* src/Chatbot.css */
.chatbot {
    position: fixed;
    bottom: 50px;
    right: 0;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .chat-window {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    height: 300px;
  }
  
  .message {
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
  }
  
  .message.user {
    background-color: #d1e7dd;
    align-self: flex-end;
  }
  
  .message.bot {
    background-color: #f8d7da;
    align-self: flex-start;
  }
  
  .chat-input {
    display: flex;
    border-top: 1px solid #ddd;
  }
  
  .chat-input input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 0 0 0 8px;
  }
  
  .chat-input button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 0 0 8px 0;
    cursor: pointer;
  }
  
  .chat-input button:hover {
    background-color: #0056b3;
  }
  

  .todaysplan{width: 100%;
  height:290px;
background-color:white;
}

.scheduleprepare{
  width:70%;
  height:200px;
background-color:rgba(220, 212, 212, 0.644);
border-radius:7px;
margin-left:5%;
padding: 12px 45px;
}