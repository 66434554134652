.radioGroup {
    text-decoration: none;
}
.radioGroup .form-check-input{
    display: none;
}
.radioGroup .form-check:has(input.form-check-input) .form-check-label {
    
    padding: .3em;
    border-radius: .2em;
    background: var(--bs-secondary);
}
.radioGroup .form-check:has(input.form-check-input:checked) .form-check-label {
    /* .form-check-label{ */
    background: var(--bs-primary)
}