.view-full {
    width: 100%;
    height: auto;
    border: 2px solid #fff;
    border-radius: 1.5em;
    padding: 1.5em;
    align-items: start;
}
.view-compact .problemCont{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.view-full .problemCont{
    display: flex;
    flex-direction: column;
} 
.view-full .assistLine{
    display: unset;
    margin:.5em 0;
    width: 100%;
}
.view-full .assist{
    display: unset;
    color:#999;
}
.view-full .assist:after{
    content:':';
    margin-right: 1em;
}
.view-full .cont1,.view-full .cont2 {
    width: 100%;
    display: flex;
    flex-direction: column;
} 
.view-full .cont3{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.view-full .hint {
    display: inline-flex;
    flex-direction: column;
}

.view-compact .assist{
    display: none;
}
.view-compact .assistLine{
    display: none;
}
.view-compact .cont1{
    font-style:normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} 
.view-compact .cont2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.hintCont{
    display: inline-flex;
}
.view-compact .cont3{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
   
    
}
.view-compact .hint {
    align-items: stretch;
    justify-content: space-between
}
 .view-compact .fileHolder{
    align-self: end;
}
.view-compact .submit{
    align-self: end;
} 


/* Parent container 
.hintCont {
    display: flex;
    flex-direction: column; /* Stack items vertically 
    align-items: flex-start; /* Aligns items to the left
    text-align: left; /* Ensures text aligns left 
}

/* Ensure individual elements align correctly 
.hintCont a, 
.hintCont div, 
.hintCont span {
    text-align: end;
    width: 100%; /* Makes sure it spans the full width 
}

*/











@media (max-width: 768px) {
    .reversecolumn {
        display: flex;
        flex-direction: column-reverse;
    }
    .hintCont{
        padding-left:-25px
    }
    .hintContsubmit {
        margin-top: 40px;
    }
    .mt-0 {
        margin-top: 15px !important;
    }
        .mb-0 {
            margin-bottom: 6px !important;
        }

        
    }
  
@media (min-width: 1200px) {
    .status {
        display: contents;
    }
    .hintCont {
        justify-content: center;
    }
    .hintContsubmit {
        margin-left : 8px;
    }
}
input[type=radio] {
    border-color: black;
}


.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
/* 
  .dotdot {


    position: absolute;
    inset: 11px -13px auto;
    transform: translate3d(-61px, 25px, 0px);

  }
  /* Default styles for larger screens */
  /* @media (max-width: 768px) {
    .dotdot {
   
    float: right;
   flex:start;
    margin-top: -12%;
        width: max-content;
        
    position: absolute;
    inset: 11px -13px auto;
    transform: translate3d(-61px, 25px, 0px);
    } */

/* Ensure the dropdown menu is centered when clicking the three dots */
.dotdot {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px; /* Adjust width as needed */
  text-align: center;
}

/* Ensure the icon stays aligned properly */
.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Responsive Styles */
@media (max-width: 576px) {  /* Extra Small Screens */
  .dotdot {
      min-width: 180px;
      width: 90%;
  }
}

@media (min-width: 577px) and (max-width: 768px) {  /* Small Screens (Tablets) */
  .dotdot {
      min-width: 200px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {  /* Medium Screens (Laptops) */
  .dotdot {
      min-width: 220px;
  }
}

@media (min-width: 1025px) {  /* Large Screens (Desktops) */
  .dotdot {
      min-width: 250px;
  }
}
