.glass,.glass *{
    --bs-card-bg:#fff1;
    --bs-body-bg:#fff2;
}
.card.glass{
    backdrop-filter: blur(.6rem);
}
.glass .btn,
.btn.glass{
    --bs-btn-bg:#16f5;
    --bs-btn-hover-bg:#05d9;
    --bs-btn-disabled-bg:#0259;
    backdrop-filter: blur(.3rem);
}
.glass input,
input.glass{
    backdrop-filter: blur(.2rem);
}