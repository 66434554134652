/* @import url("https://fonts.googleapis.com/css2?family=Space+Mono");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono"); */

.edz-sticky-header {
	position: sticky;
	top: 0;
	z-index: 100; /* Set a high z-index to ensure it stays on top of other elements */
  }
#root {
	overflow-x: hidden;
}
/* body {
	margin: 0;
	--bs-body-font-family: "Space Mono", monospace;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
} */
/* body:has([data-theme="dark"]) {
	color-scheme: dark;
	--bs-body-color: #dee2e6;
	--bs-body-color-rgb: 222, 226, 230;
	--bs-body-bg: #212529;
	--bs-body-bg-rgb: 33, 37, 41;
	--bs-emphasis-color: #fff;
	--bs-emphasis-color-rgb: 255, 255, 255;
	--bs-secondary-color: rgba(222, 226, 230, 0.75);
	--bs-secondary-color-rgb: 222, 226, 230;
	--bs-secondary-bg: #343a40;
	--bs-secondary-bg-rgb: 52, 58, 64;
	--bs-tertiary-color: rgba(222, 226, 230, 0.5);
	--bs-tertiary-color-rgb: 222, 226, 230;
	--bs-tertiary-bg: #2b3035;
	--bs-tertiary-bg-rgb: 43, 48, 53;
	--bs-primary-text-emphasis: #6ea8fe;
	--bs-secondary-text-emphasis: #a7acb1;
	--bs-success-text-emphasis: #75b798;
	--bs-info-text-emphasis: #6edff6;
	--bs-warning-text-emphasis: #ffda6a;
	--bs-danger-text-emphasis: #ea868f;
	--bs-light-text-emphasis: #f8f9fa;
	--bs-dark-text-emphasis: #dee2e6;
	--bs-primary-bg-subtle: #031633;
	--bs-secondary-bg-subtle: #161719;
	--bs-success-bg-subtle: #051b11;
	--bs-info-bg-subtle: #032830;
	--bs-warning-bg-subtle: #332701;
	--bs-danger-bg-subtle: #2c0b0e;
	--bs-light-bg-subtle: #343a40;
	--bs-dark-bg-subtle: #1a1d20;
	--bs-primary-border-subtle: #084298;
	--bs-secondary-border-subtle: #41464b;
	--bs-success-border-subtle: #0f5132;
	--bs-info-border-subtle: #087990;
	--bs-warning-border-subtle: #997404;
	--bs-danger-border-subtle: #842029;
	--bs-light-border-subtle: #495057;
	--bs-dark-border-subtle: #343a40;
	--bs-heading-color: inherit;
	--bs-link-color: #6ea8fe;
	--bs-link-hover-color: #8bb9fe;
	--bs-link-color-rgb: 110, 168, 254;
	--bs-link-hover-color-rgb: 139, 185, 254;
	--bs-code-color: #e685b5;
	--bs-border-color: #495057;
	--bs-border-color-translucent: rgba(255, 255, 255, 0.15);
	--bs-form-valid-color: #75b798;
	--bs-form-valid-border-color: #75b798;
	--bs-form-invalid-color: #ea868f;
	--bs-form-invalid-border-color: #ea868f;
} */
/* 
textarea,
input {
	font-family: "IBM Plex Mono", monospace !important;
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}
.badge {
	user-select: none;
	pointer-events: none;
}
.max-w-min {
	max-width: min-content;
}
.maxw600 {
	max-width: 600px;
}
.tdnobg > td {
	background: none !important;
}
.rot-90-text{
	transform: rotate(90deg);
	transform-origin: 0 0;
	margin: 0;
	height: 0;
}
.rot-n-45-text{
    transform: rotate(-45deg);
    transform-origin: 0 0;
    margin: 0;
    height: 0;
} */