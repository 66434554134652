.footer {
    /* background-color: #f2aa4cff; */
    background-color: rgba(11,87,208,1);
    color: white;
    display: flex;
    height : 50px;
    flex-wrap: wrap;
}

.footer .slogan {
    color: inherit;
}
.social{
    color: inherit;
    transition: all .3s ease;
}
.social:hover,
.social:focus{
	border: none;
    outline: none;
    color: var(--origColor);
    scale: 1.2;
    animation: fa-shake .3s ease-in-out;
}