/* Calendar Container */
.calendar {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  /* Event Form */
  .event-form {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .event-form input[type="date"],
  .event-form input[type="text"],
  .event-form button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .event-form button {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .event-form button:hover {
    background-color: #218838;
  }
  
  /* Edit Button */
  .edit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  /* Calendar Grid */
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin-top: 20px;
  }
  
  .calendar-day {
    font-weight: bold;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .calendar-cell {
    height: 100px;
    border: 1px solid #ddd;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
  }
  
  .calendar-cell:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  /* Event Display */
  .event-list {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
  }
  
  .event-list strong {
    display: block;
    margin-bottom: 5px;
  }
  
  .event-list p {
    margin: 0;
    font-size: 12px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .calendar-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .event-form {
      flex-direction: column;
      align-items: stretch;
    }
  
    .event-form input[type="text"],
    .event-form input[type="date"] {
      width: 100%;
    }
  
    .calendar {
      width: 100%;
    }
  }
