.accordBody {
    display: flex;
    flex-direction: column;
    font-style: arial;
}
.pageLink{
    text-decoration: none;
    color: currentColor;
}
/* Add this CSS to hide the default triangle icon */
.custom-dropdown > .nav-link::after {
    display: none !important;
  }