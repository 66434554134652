.subject {
    font-family:'IBM plex mono', monospace;
    font-weight: normal;
    font-size: 16px;
}



.main-content {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 15px; /* Ensure there's space between the content and the sidebar */
}

.sidebar {
    width: 300px; /* Adjust width as needed */
    overflow-y: auto;
    border-left: 1px solid #dee2e6; /* Border to separate sidebar from main content */
}

.my3{
    margin-left:40rem !important;
    } 

    .sidebar {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 900;
        transition: transform 0.3s ease;
        transform: translateX(100%);
    }
    
    .sidebar.open {
        display: block;
        transform: translateX(0);
    }
    
    .filter-toggle-btn {
        display: block;
        position: fixed;
        top: 100px;
        right: 10px;
        z-index: 1060;
        background-color: #007bff;
        border: none;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
    }
    
    @media (min-width: 768px) {
        .sidebar {
            display: block;
            position: relative;
            transform: none;
        }
    
        .filter-toggle-btn {
            display: none;
        }
    }

    input[type="checkbox"] {
        border: 2px solid black;
        width: 16px;
        height: 16px;
      }
    