.loader{

}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
@keyframes bounce {
    /* write keyframes for a bounce animation */
    0%, 20%, 50%, 80%, 100% {
    
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
    
}
.particle{
    --anim-duration:1s;
    animation : bounce var(--anim-duration) ease-in-out infinite;
    animation-delay:calc(var(--anim-duration) * var(--i));
}