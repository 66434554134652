.navBar{
    /* background:#f2aa4cff; */
    background-color: rgba(11,87,208,1);
    color: #fff;
}
.navBar a{
    --bs-nav-link-hover-color:black;
    color: inherit;
    text-decoration: none;
}
.navBar a:hover{
    color:var(--bs-nav-link-hover-color)
}

