
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  
  .signup-container {
    text-align: center;
  }
  
  .container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .container button {
    width: 40%;
    padding: 10px;
    background-color: #007bff; 
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block; 
    margin: 0 auto;
  }
  
  .container button:hover {
    background-color: #0056b3; 
  }
  .InternSection {
    margin-top: 50px;
  }
  .ZoomSection {
    margin-top: 30px;
  }
  .internship_img {
    display: flex;
    margin: auto;
    margin-bottom: 30px;
    border: 5px solid black;
  }
  .Zoominfo {
    font-size: 18px;
  }