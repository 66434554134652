/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .container {
        width: 90%; 
        border: 2px;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .container {
        width: 80%; 
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .container {
        width: 40%; 
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .container {
        width: 40%; 
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .container {
        width: 40%; 
    }
}

.content {
    height: 450px;
}

/* .loginCard{
    --glassPrimary:#fff1;
    max-width: 600px;
}
.showPassword{
    width: 0;
    transform: translateX(-2em) !important;
    padding: 0;
    margin: 0;
    background:none !important;
    border: none !important;
    outline: none !important;
    color: currentColor !important;
}
.validationIcon {
    pointer-events: none;
    margin:.2em;
    --fa-animation-iteration-count: 1;
    opacity: 0;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* .validationIcon.correct {
    color: #2c2c;
    animation: fa-bounce  1s var(--fa-animation-iteration-count, infinite) ease,
        fadeOut 1s ease-in-out;
}

.validationIcon.inCorrect {
    color: #c22c;
    animation: fa-shake 1s var(--fa-animation-iteration-count, infinite) ease,
        fadeOut 1s ease-in-out;
} 

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /background: url('../images/login.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    align-content: center;
}


.container {
    border: 2px solid;
    backdrop-filter : blur(20px);
    border-radius: 10px;
    padding: 40px 40px 40px 40px; 
    width: 500px;
    overflow-x: hidden;
}

@media (max-width: 768px) {
    .container {
        width: 85%; 
    }
}

.form {
    width: 100%;
    gap: 0;
}
@media (max-width: 768px) {
    .form {
        width: 100%; 
    }
}

.container h1{
    font-size: 36px;
    text-align: center;
}
.container .input-box {
    width: 100;
    height: 50px;
    margin: 30px;
}

@media (max-width: 768px) {
    .container .input-box  {
        width: 90%;
    }
}

.container input {
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: none;
}

.input-box input {
    width: 150;
    height: 100;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .2);
    border-radius: 40px;
    font-size: 16px;
    color: white;
}

.input-box input::placeholder {
    color: white;
}

.input-box i {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.container .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}
.remember-forgot label input {
    accent-color: white;
    margin-right: 3px;
}
.remember-forgot a {
    color: white;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

.container .btn {
    width: 100;
    height: 45px;
    background: whitesmoke;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow:  0 0 10px rgba(0, 0, 0, -1);
    cursor:pointer;
    font-size: 16px;
    color: #333;
    font-weight: 600;
}

.container .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a {
    color:white;
    text-decoration: none;
    font-weight: 600;
    text-align: center;
}

.register-link p a:hover {
    text-decoration: underline;
    color: white;
}


.login {
}
*/ 

.register p {
    text-align: center;
}