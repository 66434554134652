.btnposition {
    position: absolute;
    top: 58%;
    right: 30%
}

@media (max-width: 768px) {
    .btnposition {
        position: absolute;
        /* bottom: 50%; */
        right: 115px;
        bottom: 0px !important;
        /* position: fixed;
          bottom: 0;
          top: 0; */
    }
}


.table-container {
    width: 100%;
    overflow-x: auto;
    margin-left: 5%;
    display: flex;
    justify-content: start;
    flex-direction: column;


}

.user-table {
    border-collapse: collapse;
    width: 100%;
    max-width: 1200px;

}

.user-table th {
    background-color: #0d6efd;
    color: white;
    padding: 5px;
    text-align: left;
    font-weight: 600;
}

.user-table td {
    border: 1px solid #ddd;
    padding: 8px;
    white-space: nowrap;

}

.user-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table tr:hover {
    background-color: #f1f1f1;
}


@media screen and (max-width: 600px) {
    .title {
        font-size: 18px;
        text-align: center;
    }

    .table-container {
        width: 100%;
        margin-left: 12px;
    }

    .user-table {
        width: 100%;
        font-size: 14px;
    }

    .user-table th,
    .user-table td {
        padding: 6px;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .title {
        font-size: 20px;
    }

    .user-table {
        width: 85%;
        font-size: 16px;
    }

    .user-table th,
    .user-table td {
        padding: 8px;
    }
}

@media screen and (min-width: 1025px) {
    .title {
        font-size: 24px;
    }

    .user-table {
        width: 80%;
        font-size: 18px;
    }

    .user-table th,
    .user-table td {
        padding: 10px;
    }
}

.table1-container1 {
    width: 100%;
    overflow-x: auto;
    margin-left: 5%;

}

.user1-table1 {
    border-collapse: collapse;
    width: 100%;
    max-width: 1200px;

}

.user1-table1 th {
    background-color: #0d6efd;
    color: white;
    padding: 5px;
    text-align: left;
    font-weight: 600;
}

.user1-table1 td {
    border: 1px solid #ddd;
    white-space: nowrap;
    padding: 8px;

}

.user1-table1 tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user1-table1 tr:hover {
    background-color: #f1f1f1;
}


@media screen and (max-width: 600px) {
    .title1 {
        font-size: 18px;
        text-align: center;
    }

    .table1-container1 {
        width: 100%;
        margin-left: 12px;
    }

    .user1-table1 {
        width: 100%;
        font-size: 14px;
    }

    .user1-table1 th,
    .user1-table1 td {
        padding: 6px;
    }
}


@media screen and (min-width: 601px) and (max-width: 1024px) {
    .title1 {
        font-size: 20px;
    }

    .user1-table1 {
        width: 85%;
        font-size: 16px;
    }

    .user1-table1 th,
    .user1-table1 td {
        padding: 8px;
    }
}

@media screen and (min-width: 1025px) {
    .title1 {
        font-size: 24px;
    }

    .user1-table1 {
        width: 80%;
        font-size: 18px;
    }

    .user1-table1 th,
    .user1-table1 td {
        padding: 8px;
    }
}

.communitybutton {
    float: right;
    padding-top: 3px;
    margin-right: 3%;
}

.title {
    font-size: 25px;
    padding: 10px 5%;
    font-family: sans-serif;


}

/* Wrapper for table */
.data-table-wrapper {
    width: 100%;
    overflow-x: auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Styling the table */
.member-list-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

/* Table Header */
.member-list-table thead {
    background-color: #007bff;
    color: white;
    text-transform: uppercase;
}

.member-list-table th {
    padding: 12px 15px;
    text-align: left;
}

/* Table Body */
.member-list-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
}

/* Alternate Row Background */
.member-list-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Hover Effect */
.member-list-table tbody tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

/* Center actions column */
.member-list-table td:last-child {
    text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
    .member-list-table {
        font-size: 14px;
    }

    .member-list-table th,
    .member-list-table td {
        padding: 10px;
    }
}